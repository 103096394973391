<template>
  <div class="bck">
    <div class="shell">
      <h2 class="title">Login</h2>
      <input
        type="text"
        v-model="username"
        id="username"
        placeholder="用户名"
      />
      <input
        type="password"
        v-model="password"
        id="password"
        placeholder="密码"
      />
      <input type="text" v-model="name" id="password" placeholder="名字" />
      <input type="submit" @click="login()" value="登录" id="loginBtn" />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref } from "vue";
import router from "../router/index";
import { getCurrentInstance } from "vue";
export default {
  components: {},
  setup() {
    const store = useStore();
    let username = ref("");
    let password = ref("");
    let name = ref("");
    const { proxy } = getCurrentInstance();
    const jwt_token = localStorage.getItem("jwt_token");
    if (jwt_token) {
      store.commit("updateToken", jwt_token);
      store.dispatch("getinfo", {
        success() {
          router.push({ name: "home" });
          store.commit("updatePulling", false);
        },
        error() {
          store.commit("updatePulling", false);
        },
      });
    } else {
      store.commit("updatePulling", false);
    }

    const login = () => {
      store.dispatch("login", {
        username: username.value,
        password: password.value,
        name: name.value,
        success() {
          store.dispatch("getinfo", {
            success() {
              router.push({ name: "home" });
            },
          });
        },
        error(resp) {
          if (resp.code == 0) {
            proxy.$Message.info({
              content: resp.msg,
              duration: 2,
            });
          }
        },
      });
    };

    return {
      username,
      password,
      name,
      login,
    };
  },
};
</script>

<style scoped>
button {
  width: 100%;
}
div.error-message {
  color: red;
}
* {
  padding: 0;
  margin: 0;
  transition: 0.3s;
}

.bck {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url("https://pic.imge.cc/2024/09/10/66e03d8f27874.jpg");
  background-size: cover;
}

.shell {
  width: 350px;
  padding: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff49;
  border-radius: 50px;
  box-shadow: 0 0 30px rgba(255, 255, 255, 0.5) inset;
  transform: translateY(-50px);
}

.title {
  font-size: 80px;
  margin-bottom: 30px;
  color: #fff;
  text-shadow: 0 0 10px #ff9dff80;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  height: 50px;
  margin: 10px 0;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  border: 5px solid transparent;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 100px;
  padding: 5px 20px 0 20px;
  transition: 0.3s;
  font-size: 18px;
  outline: none;
}

input[type="text"]:hover,
input[type="password"]:hover {
  background: rgba(255, 255, 255, 0);
  border: 5px solid #ffffff;
}

input[type="submit"] {
  width: 100%;
  height: 50px;
  padding: 10px;
  margin: 15px 0;
  border-radius: 100px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  letter-spacing: 3px;
}

input::placeholder {
  color: #92a7e8;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.Remember {
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #7597ff;
}

input[type="checkbox"] {
  display: block;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  background-color: #007bff;
}

#Password {
  border: none;
  background-color: #ffffff00;
  color: #7597ff;
  font-size: 18px;
}
</style>