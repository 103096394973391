import { createRouter, createWebHistory } from 'vue-router'

import NotfoundView from "../views/NotfoundView.vue"
import HomeView from "../views/HomeView.vue"
import store from '@/store'
import login from "../views/UserAccountLoginView.vue"

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requestAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      requestAuth: false
    }
  },
  {
    path: '/404/',
    name: '404',
    component: NotfoundView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/404/",
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.requestAuth && !store.state.user.is_login) {
    next({ name: "login" });
  } else {
    next();
  }
})


export default router
