
export default {
    state: {
        opponent_name: "",
        opponent_photo: "",
        aId: "",
        bId: "",
        socket: null,
        status: "matching",
        gamemap: null,
        loser: "none",
        gameObject: null,
        is_loading: false,
        second: 30
    },
    getters: {
    },
    mutations: {
        updateOpponent(state, opponent) {
            state.opponent_photo = opponent.photo;
            state.name = opponent.name;
        },
        updateStatus(state, status) {
            state.status = status;
        },
        updateSecond(state, time) {
            state.second = time;
        },
        lowSecond(state, t) {
            state.second += t;
        },
        updateSocket(state, socket) {
            state.socket = socket;
        },
        updateLoser(state, loser) {
            state.loser = loser;
        },
        updateGameObject(state, object) {
            state.gameObject = object;
        },
        updateUserId(state, game) {
            state.aId = game.a_id;
            state.bId = game.b_id;
        }
    },
    actions: {

    },
    modules: {
    }
}
