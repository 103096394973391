import { AcGameObject } from "./AcGameObject";
import { River } from "./River";
import { Beast } from './Beast';
import { Home } from './Home';
import { Trap } from './Trap';
export class GameMap extends AcGameObject {
    constructor(ctx, parent, store) {
        super();

        this.ctx = ctx;
        this.parent = parent;
        this.L = 0;

        this.rows = 9;
        this.cols = 7;
        this.store = store;
        this.inner_walls_count = 20;
        this.walls = [];
        this.homes = [];
        this.traps = [];
        this.beasts = []

        this.Beasts = ["狮", "虎", "狗", "猫", "鼠", "豹", "狼", "象"];
        this.power = [7, 6, 3, 2, 1, 5, 4, 7]; // 动物攻击力排行

        this.next_player = 0;
    }

    create_walls() {
        // 二维空数组
        const g = [];
        for (let r = 0; r < this.rows; r++) {
            g[r] = [];
            for (let c = 0; c < this.cols; c++) {
                g[r][c] = false;
            }
        }
        // 建河
        for (let i = 0; i < 3; i++) {
            for (let j = 0; j < 2; j++) {
                g[i + 3][j + 1] = g[i + 3][j + 4] = true;
                this.walls.push(new River(i + 3, j + 1, this));
                this.walls.push(new River(i + 3, j + 4, this));
            }
        }
        // 建陷阱
        this.traps.push(new Trap(0, 2, this, 0), new Trap(0, 4, this, 0), new Trap(1, 3, this, 0));
        this.traps.push(new Trap(8, 2, this, 1), new Trap(8, 4, this, 1), new Trap(7, 3, this, 1));
        // 建家
        this.homes.push(new Home(0, 3, this, 1), new Home(8, 3, this, 2));

        // 猛兽
        this.beasts = [new Beast({ id: 0, color: "#FF00FF" }, this, 0, 3),
        new Beast({ id: 1, color: "#4876EC" }, this, 8, 3)]
    }

    add_listening_events() {
        this.ctx.canvas.focus();

        this.ctx.canvas.addEventListener("click", e => {
            let r = parseInt((e.offsetY + this.L) / this.L);
            let c = parseInt((e.offsetX + this.L) / this.L);
            if (r >= 0 && r <= 9 && c >= 0 && c <= 7) {
                this.store.state.pk.socket.send(JSON.stringify({
                    event: "move",
                    userId: this.store.state.user.id,
                    x: r - 1,
                    y: c - 1,
                }));
            }
        });
    }

    start() {
        this.create_walls();
        this.add_listening_events();
    }

    update_size() {
        this.L = parseInt(Math.min(this.parent.clientWidth / this.cols, this.parent.clientHeight / this.rows));
        this.ctx.canvas.width = this.L * this.cols;
        this.ctx.canvas.height = this.L * this.rows;
    }



    update() {
        this.update_size();
        this.render();
    }

    render() {
        let color_even = "#AAD751", color_odd = "#A2D149";
        for (let r = 0; r < this.rows; r++) {
            for (let c = 0; c < this.cols; c++) {
                if ((r + c) % 2 == 0) {
                    this.ctx.fillStyle = color_even;
                } else {
                    this.ctx.fillStyle = color_odd;
                }
                this.ctx.fillRect(c * this.L, r * this.L, this.L, this.L);
            }
        }

        // 划线
        for (let c = 0; c < 10; c++) {
            this.ctx.moveTo(0, c * this.L);
            this.ctx.lineTo(this.L * 9, c * this.L);
            this.ctx.strokeStyle = "#F5FFFA"
            this.ctx.lineWidth = 5;
            this.ctx.stroke();
        }
        for (let c = 0; c < 10; c++) {
            this.ctx.moveTo(c * this.L, 0);
            this.ctx.lineTo(c * this.L, this.L * 9);
            this.ctx.strokeStyle = "#F5FFFA"
            this.ctx.lineWidth = 5;
            this.ctx.stroke();
        }
    }
}