<template>
  <nav class="navbar navbar-expand-xl bg-body-tertiary">
    <div class="container">
      <button data-text="Awesome" class="button" @click="reGame()">
        <router-link :to="{ name: 'home' }" class="actual-text"
          >&nbsp;FengYi&nbsp;</router-link
        >
        <router-link
          :to="{ name: 'home' }"
          class="hover-text"
          aria-hidden="true"
          >&nbsp;FengYi&nbsp;</router-link
        >
      </button>
      <div v-if="$store.state.user.is_login">
        {{ $store.state.user.name }}
      </div>
      <div v-else-if="!$store.state.user.pulling_info">
        <router-link :to="{ name: 'login' }" role="button"> 登录 </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
  setup() {
    const reGame = () => {
      console.log("11");
      location.reload();
    };
    return {
      reGame,
    };
  },
};
</script>

<style scoped>
/* === removing default button style ===*/
.button {
  margin: 0;
  height: auto;
  background: transparent;
  padding: 0;
  border: none;
}

/* button styling */
.button {
  --border-right: 6px;
  --text-stroke-color: rgba(255, 255, 255, 0.6);
  --animation-color: lightpink;
  --fs-size: 2em;
  letter-spacing: 3px;
  text-decoration: none;
  font-size: var(--fs-size);
  font-family: "Arial";
  position: relative;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke: 1px var(--text-stroke-color);
}

/* this is the text, when you hover on button */
.hover-text {
  position: absolute;
  box-sizing: border-box;
  content: attr(data-text);
  color: var(--animation-color);
  width: 0%;
  inset: 0;
  border-right: var(--border-right) solid var(--animation-color);
  overflow: hidden;
  transition: 0.5s;
  -webkit-text-stroke: 1px var(--animation-color);
}

/* hover */
.button:hover .hover-text {
  width: 100%;
  filter: drop-shadow(0 0 23px var(--animation-color));
}
</style>