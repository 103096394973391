<template>
  <!-- <div style="height: 100%;width: 100%;background-color: red;"></div> -->
  <Exception type="404" img-color />
</template>
<script>
export default {
  name: "404Notfound",
};
</script>

<style scoped></style>