import { AcGameObject } from "./AcGameObject";
import { Anmail } from "./Anmail";

export class Beast extends AcGameObject {
    constructor(info, gamemap, homeX, homeY) {
        super();

        this.id = info.id;
        this.color = info.color;
        this.gamemap = gamemap;
        this.home_x = homeX;
        this.home_y = homeY;
        this.Beasts = ["狮", "虎", "狗", "猫", "鼠", "豹", "狼", "象"];
        this.power = [7, 6, 3, 2, 1, 5, 4, 8]; // 动物攻击力排行
        this.dy = [0, 6, 1, 5, 0, 2, 4, 6]; // 偏移量
        this.dx = [0, 0, 1, 1, 2, 2, 2, 2];
        this.trap_dx = [0, 0, 1, 8, 8, 7]; // 陷阱位置
        this.trap_dy = [2, 4, 3, 2, 4, 3];
        this.beasts = [];  // 8个棋子

        this.is_win = false;
        this.status = "idle";  // idle表示静止，move表示正在移动，die表示死亡

        this.be_status = [1, 1, 1, 1, 1, 1, 1];
        this.be_x;
        this.be_y;
        this.bg = -1;
        this.animations = new Map();
    }

    start() {
        if (this.id == 0) {
            for (let i = 0; i < 8; i++) {
                this.beasts.push(new Anmail(this.dx[i], this.dy[i], this.Beasts[i], this.gamemap, this.id, 0.8, "#FF00FF", 1, 0));
            }
        } else {
            for (let i = 0; i < 8; i++) {
                this.beasts.push(new Anmail(8 - this.dx[i], 6 - this.dy[i], this.Beasts[i], this.gamemap, this.id, 0.8, "#4876EC", 1, 0));
            }
        }

        console.log(this.beasts);
    }

    say() {
        console.log("saysaysaysaysay");
    }
    big(i, l) {
        const a = this.beasts[i];
        this.beasts.splice(i, 1, new Anmail(a.r, a.c, a.name, this.gamemap, a.id, l, a.color, 1, 0));
    }

    move(r, c, i, trap) {
        const a = this.beasts[i];
        this.beasts.splice(i, 1, new Anmail(r, c, a.name, this.gamemap, a.id, 0.8, a.color, 1, trap));
    }
    die(i) {
        const a = this.beasts[i];
        this.beasts.splice(i, 1, new Anmail(a.r, a.c, a.name, this.gamemap, a.id, 0.8, a.color, 0, 0));
    }


    update() {  // 每一帧执行一次
        this.render();
    }

    render() {
        const L = this.gamemap.L;
        let ctx = this.gamemap.ctx;


        let i = 0;
        for (const b of this.beasts) {
            if (b.status == 1) {
                ctx.fillStyle = this.color
                ctx.beginPath();
                ctx.arc(b.x * L, b.y * L, L / 2 * b.l, 0, Math.PI * 2);
                ctx.fill();
                ctx.font = L / 3 + "px " + '"微软雅黑"';
                ctx.fillStyle = "#E0FFFF";


                ctx.fillText(this.Beasts[i], (b.x - 0.1) * L - 2, (b.y + 0.1) * L - 2);
            }
            i++;
        }
    }
}