<template>
  <OutWall>
    <NavBar></NavBar>
    <div style="margin-top: 5vh; background-color: rgba(0, 0, 0, 0)">
      <PkIndexView></PkIndexView>
    </div>
  </OutWall>
</template>

<script>
import PkIndexView from "./PkIndexView.vue";
// import { getCurrentInstance } from "vue";
import NavBar from "@/components/NavBar.vue";
import OutWall from "@/components/OutWall.vue";
import router from "@/router/index";
export default {
  name: "HomeView",
  components: {
    NavBar,
    OutWall,
    PkIndexView,
  },
  setup() {
    // const instance = getCurrentInstance();

    const open_game = () => {
      router.push({
        name: "pk",
      });
    };

    return {
      open_game,
    };
  },
};
</script>

<style scoped>
.card-client {
  background: #2cb5a0;
  width: 13rem;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 20px;
  padding-right: 20px;
  border: 4px solid #7cdacc;
  box-shadow: 0 6px 10px rgba(207, 212, 222, 1);
  border-radius: 10px;
  text-align: center;
  color: #fff;
  font-family: "Poppins", sans-serif;
  transition: all 0.3s ease;
}

.demo-img > img {
  width: 100%;
}

.layout-base {
  border: 1px solid #d7dde4;
  background: #f5f7f9;
  position: relative;
  border-radius: 4px;
  color: white;
  border-style: dashed;
  border-width: 3px;
  border-color: #ffd1ff;
}

.layout-base > .ivu-layout {
  margin-bottom: 20px;
}

.layout-header,
.layout-footer {
  background: #7cbce9;
  color: white;
}

.layout-content {
  background-color: #dbeffd;
}

.user-info {
  height: 5vh;
}

button {
  border: none;
  border-radius: 10em;
  background: #016dd9;
  font-size: 17px;
  color: #ffffff;
  font-family: inherit;
  font-weight: 500;
}

/* button:hover {
    animation: shake3856 0.3s linear infinite both;
} */
</style>