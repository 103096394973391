
export class Anmail {
    constructor(r, c, name, gamemap, id, l, color, status, trap) {

        this.l = l;
        this.r = r;
        this.c = c;
        this.x = c + 0.5;
        this.y = r + 0.5;
        this.name = name;
        this.id = id;
        this.gamemap = gamemap;
        this.color = color
        this.status = status;
        this.trap = trap;
    }

    start() {

    }
}