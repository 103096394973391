<template>
  <div style="background-color: rgba(0, 0, 0, 0)">
    <PlayGround v-if="$store.state.pk.status === 'playing'" />
    <MatchGround v-if="$store.state.pk.status === 'matching'" />
    <GameLoading v-if="$store.state.pk.status === 'loading'"></GameLoading>
  </div>
  <!-- <ResultBoard v-if="$store.state.pk.loser != 'none'" /> -->
</template>

<script>
import MatchGround from "@/components/MatchGround.vue";
import PlayGround from "@/components/PlayGround.vue";
import { onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { getCurrentInstance } from "vue";
import GameLoading from "@/components/GameLoading.vue";
export default {
  components: {
    PlayGround,
    MatchGround,
    GameLoading,
    // ResultBoard,
  },
  setup() {
    const store = useStore();
    const socketUrl = `wss://www.yblog.fun/websocket/${store.state.user.token}/`;
    const { proxy } = getCurrentInstance();
    store.commit("updateLoser", "none");

    let socket = null;
    onMounted(() => {
      store.commit("updateOpponent", {
        username: "我的对手",
        photo:
          "https://cdn.acwing.com/media/article/image/2022/08/09/1_1db2488f17-anonymous.png",
      });
      socket = new WebSocket(socketUrl);

      socket.onopen = () => {
        console.log("connected!");
        store.commit("updateSocket", socket);
      };

      socket.onmessage = (msg) => {
        const data = JSON.parse(msg.data);
        if (data.event == "matching-success") {
          // 匹配成功
          store.commit("updateOpponent", {
            name: data.opponent_username,
            photo: data.opponent_photo,
          });
          let firstId = data.game.firstId;
          if (firstId == store.state.user.id) {
            proxy.$Message.info({
              content: "你的先手，红色棋子",
              duration: 5,
            });
          } else {
            proxy.$Message.info({
              content: "你的后手，蓝色棋子",
              duration: 5,
            });
          }
          store.commit("updateStatus", "loading");
          setTimeout(() => {
            store.commit("updateStatus", "playing");
            store.commit("updateSecond", 14);
          }, 1000);
        } else if (data.event == "bg") {
          store.commit("updateSecond", 15);
          const game = store.state.pk.gameObject;
          const [player0, player1] = game.beasts;
          if (data.nextPlayer == 0) {
            player1.big(data.i, data.l);
          } else {
            player0.big(data.i, data.l);
          }
        } else if (data.event == "move") {
          store.commit("updateSecond", 15);
          let r = data.r;
          let c = data.c;
          let i = data.i;
          let trap = data.trap;
          const game = store.state.pk.gameObject;
          const [player0, player1] = game.beasts;
          if (data.nextPlayer == 0) {
            player1.move(r, c, i, trap);
          } else {
            player0.move(r, c, i, trap);
          }
        } else if (data.event == "die") {
          const game = store.state.pk.gameObject;
          const [player0, player1] = game.beasts;
          console.log(data.nextPlayer + "  " + data.i);
          if (data.nextPlayer == 0) {
            player0.die(data.i);
          } else if (data.nextPlayer == 1) {
            player1.die(data.i);
          } else {
            player0.die(data.i);
            player1.die(data.i);
          }
        } else if (data.event == "result") {
          store.commit("updateLoser", data.loser);
          // proxy.$Message.info({
          //           content: des,
          //           duration: 0
          //       });
          // 输了
          if (data.loser == store.state.user.id) {
            proxy.$Notice.error({
              title: "游戏结束！胜负已定",
              desc: "失败！",
              duration: 30,
            });
          } else {
            proxy.$Notice.success({
              title: "游戏结束！胜负已定",
              desc: "胜利",
              duration: 30,
            });
          }
        } else if (data.event == "chat") {
          let name = data.name;
          let info = data.info;

          let des = name + "说: " + info;
          proxy.$Message.info({
            content: des,
            duration: 2,
          });
        }
      };

      socket.onclose = () => {
        console.log("disconnected!");
      };
    });

    onUnmounted(() => {
      socket.close();
      store.commit("updateStatus", "matching");
    });
  },
};
</script>

<style scoped>
</style>