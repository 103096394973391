<template>
    <!-- <span class="name">
        <button class="btn" type="submit">Login</button>
    </span> -->
    <div ref="parent" class="gamemap">
        <canvas ref="canvas" tabindex="0" style="margin"></canvas>
    </div>
    <!-- <div class="info"></div> -->
</template>

<script>
import { GameMap } from "@/assets/script/GameMapBeasts.js"
// import { getCurrentInstance } from "vue";
import { ref, onMounted } from 'vue'
import { useStore } from "vuex";
export default {
    setup() {
        // let instance = getCurrentInstance();
        let parent = ref(null);
        let canvas = ref(null);
        const store = useStore();
        onMounted(() => {
            store.commit(
                "updateGameObject",
                // new GameMap(canvas.value.getContext('2d'), parent.value, store, instance)
                new GameMap(canvas.value.getContext('2d'), parent.value, store)
            );
            console.log(store.state.pk.gameObject);
        });

        return {
            parent,
            canvas
        }
    }
}
</script>

<style scoped>
div.gamemap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.name {
    display: inline;
}
.info{
    width: 100%;
    height: 20px;
    background-color: aqua
}
</style>
