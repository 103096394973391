import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'

// 使用use将文件挂载上去
createApp(App).use(router).use(store).use(ViewUIPlus).mount('#app')

