<template>
  <div
    class="ww"
    style="
      height: 100vh;
      width: 100vw;
      min-width: 99vw;
      min-height: 99vh;
      color: black;
      padding-top: 15px;
      padding-bottom: 15px;
    "
  >
    <div class="outwall">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "outwallS",
};
</script>
<style scoped>
.outwall {
  overflow: auto;
  height: 90vh;
  width: 90vw;
  /* min-height: 90vh;
  min-width:90vw; */
  margin: 20px auto;
  border-radius: 20px;
  box-shadow: 15px 10px 15px rgba(0, 0, 255, 0.2);
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 255, 0.2);
  background-image: url("../assets/3-2006021020445b.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: no-repeat;
}

.ww {
  background-color: #dfdbe5;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='72' viewBox='0 0 36 72'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.4'%3E%3Cpath d='M2 6h12L8 18 2 6zm18 36h12l-6 12-6-12z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
/*滚动条的宽度*/

::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

/*外层轨道。可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果*/

::-webkit-scrollbar-track {
  width: 6px;
  background-color: white;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

/*滚动条的设置*/

::-webkit-scrollbar-thumb {
  background-color: lightblue;
  background-clip: padding-box;
  min-height: 28px;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
/*滚动条移上去的背景*/

::-webkit-scrollbar-thumb:hover {
  background-color: #fff;
}
</style>