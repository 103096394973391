<template>
  <div class="playground">
    <div style="position: absolute; width: 100%">
      {{ newTime }}
    </div>
    <GameMap></GameMap>
    <input
      type="text"
      v-model="info"
      id="username"
      placeholder="        弹幕"
    />
    <Button
      type="success"
      style="width: 5px; margin-left: 1%; height: 45px"
      @click="send()"
    ></Button>
  </div>
</template>

<script>
import { useStore } from "vuex";
import GameMap from "./GameMap.vue";
import { ref } from "vue";
// import { getCurrentInstance } from "vue";
export default {
  components: {
    GameMap,
  },
  setup() {
    const newTime = ref("");
    let clock; //计时器
    // const { proxy }  = getCurrentInstance();
    let info = ref("");
    const store = useStore();
    let targetTime2 = new Date().getTime() + 10000;
    console.log(targetTime2);

    const send = () => {
      // proxy.$Message.loading('This is a normal message');
      store.state.pk.socket.send(
        JSON.stringify({
          event: "chat",
          info: info.value,
          name: store.state.user.name,
        })
      );
      info.value = "";
    };
    const timer = () => {
      if (store.state.pk.second > 0) {
        store.commit("lowSecond", -1);
      }

      newTime.value = store.state.pk.second;
    };
    const startTimer = () =>
      //开始
      {
        clock = setInterval(timer, 1000);
        console.log(clock);
      };
    startTimer();

    return {
      send,
      info,
      targetTime2,
      newTime,
    };
  },
};
</script>

<style scoped>
div.playground {
  height: 72vh;
  width: 56vw;
  /* background-image: url('https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fi0.hdslb.com%2Fbfs%2Farticle%2F9ee1b22f8b612cf652a3a7d15b7a11f40b9c8e8c.png&refer=http%3A%2F%2Fi0.hdslb.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1661876971&t=621b129b607eec291555e5c8d929cdbd'); */
  margin: 40px auto;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.shell {
  width: 350px;
  padding: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff49;
  border-radius: 50px;
  box-shadow: 0 0 30px rgba(255, 255, 255, 0.5) inset;
  transform: translateY(-50px);
}

.title {
  font-size: 80px;
  margin-bottom: 30px;
  color: #fff;
  text-shadow: 0 0 10px #ff9dff80;
}

input[type="text"],
input[type="password"] {
  width: 83%;
  height: 50px;
  margin: 10px 0;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  border: 5px solid transparent;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 100px;

  transition: 0.3s;
  font-size: 18px;
  outline: none;
}

input[type="text"]:hover,
input[type="password"]:hover {
  background: rgba(255, 255, 255, 0);
  border: 5px solid #ffffff;
}

input::placeholder {
  color: #92a7e8;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.Remember {
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #7597ff;
}

input[type="checkbox"] {
  display: block;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  background-color: #007bff;
}

#Password {
  border: none;
  background-color: #ffffff00;
  color: #7597ff;
  font-size: 18px;
}
</style>
