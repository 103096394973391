<template>
  <router-view />
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import { startSakura } from "./assets/script/fullFllower";
export default {
  name: "App",
  components: {},
  mounted() {
    startSakura();
  },
};
</script>
<style scoped>
</style>
