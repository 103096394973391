<template>
  <div class="matchground">
    <div
      style="
        text-align: center;
        height: 15vh;
        font-weight: 600;
        color: white;
        font-style: oblique;
        font-size: 40px;
        padding-top: 5vh;
      "
    >
      {{ onMachingFont }}
      {{ newTime }}
    </div>

    <div class="player1">
      <div class="match-photo">
        <img :src="$store.state.user.photo" alt="" />
      </div>
      <div class="match-username">
        {{ $store.state.user.name }}
      </div>
    </div>
    <div style="text-align: center; margin-top: 3vh">
      <button
        @click="match_game"
        type="button"
        class="btn btn-info"
        style="margin: 0 auto"
      >
        {{ match_btn }}
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
export default {
  components: {},
  setup() {
    let match_btn = ref("开始");
    let onMachingFont = ref("凤弈对战");
    const store = useStore();
    const newTime = ref("");
    let second; //时 分 秒
    second = 0; //初始化
    let clock; //计时器
    const match_game = () => {
      if (match_btn.value === "开始") {
        match_btn.value = "取消";
        startTimer();
        onMachingFont.value = "匹配中 . . .";
        store.state.pk.socket.send(
          JSON.stringify({
            event: "start-matching",
          })
        );
      } else {
        match_btn.value = "开始";
        resetTimer();
        onMachingFont.value = "游戏";
        store.state.pk.socket.send(
          JSON.stringify({
            event: "stop-matching",
          })
        );
      }
    };

    const resetTimer = () =>
      //重置
      {
        clearInterval(clock);
        second = 0;
        newTime.value = "";
      };

    const startTimer = () =>
      //开始
      {
        clock = setInterval(timer, 1000);
      };

    //计时函数
    const timer = () => {
      second++;
      newTime.value = second;
    };

    return {
      match_btn,
      match_game,
      onMachingFont,
      newTime,
    };
  },
};
</script>

<style scoped>
div.matchground {
  height: 60vh;
  width: 60vw;
  background-image: url("../assets/裁剪.jpg");

  margin: 0 auto;
  /* background-color:aquamarine; */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 10px;
}
@media (min-width: 500px) {
  div.matchground {
    height: 60vh;
    width: 60vw;

    margin: 0 auto;
    background-image: url("../assets/图片1.jpg");

    /* background-color:aquamarine; */
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 10px;
  }
}

div.player1 {
  height: 30vh;
  width: 200px;
  /* min-width:vw; */
  background-color: rgba(0, 0, 0, 0.3);
  margin: auto;
  border-radius: 4px;
}

div.match-photo > img {
  border-radius: 50%;
  width: 50%;
}

div.match-photo {
  padding-top: 2vh;
  text-align: center;
}

div.match-username {
  margin-top: 4vh;
  height: 5vh;
  background-color: lightslategray;
  text-align: center;
  padding-top: 1vh;
  font-weight: 600;
  font-style: oblique;
}

div.vs {
  font-size: 100px;
  font-weight: 600;
  margin-top: 7vh;
  font-style: oblique;
  font-family: "Helvetica Neue";
}

button {
  padding: 10px 40px;
  font-size: 18px;
  background-color: #008542;
  color: #fff;
  text-shadow: 0 2px 0 rgb(0 0 0 / 25%);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 0;
  z-index: 1;
  user-select: none;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  white-space: unset;
  padding: 0.8rem 1.5rem;
  text-decoration: none;
  font-weight: 900;
  transition: all 0.7s cubic-bezier(0, 0.8, 0.26, 0.99);
}

button:before {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  content: "";
  transition: 0.7s cubic-bezier(0, 0.8, 0.26, 0.99);
  z-index: -1;
  background-color: #008542 !important;
  box-shadow: 0 -4px rgb(21 108 0 / 50%) inset,
    0 4px rgb(100 253 31 / 99%) inset, -4px 0 rgb(100 253 31 / 50%) inset,
    4px 0 rgb(21 108 0 / 50%) inset;
}

button:after {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  content: "";
  box-shadow: 0 4px 0 0 rgb(0 0 0 / 15%);
  transition: 0.7s cubic-bezier(0, 0.8, 0.26, 0.99);
}

button:hover:before {
  box-shadow: 0 -4px rgb(0 0 0 / 50%) inset, 0 4px rgb(255 255 255 / 20%) inset,
    -4px 0 rgb(255 255 255 / 20%) inset, 4px 0 rgb(0 0 0 / 50%) inset;
}

button:hover:after {
  box-shadow: 0 4px 0 0 rgb(0 0 0 / 15%);
}

button:active {
  transform: translateY(4px);
}

button:active:after {
  box-shadow: 0 0px 0 0 rgb(0 0 0 / 15%);
}
</style>