import { AcGameObject } from "./AcGameObject";

export class Trap extends AcGameObject {
    constructor(r, c, gamemap, id) {
        super();
        this.id = id;
        this.r = r;
        this.c = c;
        this.gamemap = gamemap;
        this.color = "#F08080";
    }

    update() {
        this.render();
    }

    render() {
        const L = this.gamemap.L;
        const ctx = this.gamemap.ctx;

        ctx.fillStyle = this.color;
        ctx.fillRect((this.c) * L + 0.05 * L, (this.r) * L + 0.05 * L, L * 0.9, L * 0.9);
    }
}